import React from 'react';
import SbEditable from 'storyblok-react';
import Hero from '../Screens/Hero';
import Layout from '../Layout/Layout';

const storyblokComponentDefault = (props) => (
  <Layout>
    <SbEditable content={props.blok}>
      <Hero
        title={props.blok.title}
        copy={props.blok.body}
        image={props.blok.image}
        id="home"
      />
    </SbEditable>
  </Layout>
);

export default storyblokComponentDefault;
