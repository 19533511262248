import storyblokComponentDefault from './storyblokComponentDefault';
import storyblokComponentDocument from './storyblokComponentDocument';
import storyblokComponentGame from './storyblokComponentGame';
import storyblokComponentGames from './storyblokComponentGames';
import storyblokComponentNotFound from './storyblokComponentNotFound';

const ComponentList = {
  default: storyblokComponentDefault,
  document: storyblokComponentDocument,
  game: storyblokComponentGame,
  games: storyblokComponentGames,
};

const Components = (type) => {
  if (typeof ComponentList[type] === 'undefined') {
    return storyblokComponentNotFound;
  }
  return ComponentList[type];
};

export default Components;
