import React from 'react';
import SbEditable from 'storyblok-react';
import Games from '../Screens/Games';

import Layout from '../Layout/Layout';

const storyblokComponentGames = (props) => (
  <Layout>
    <SbEditable content={props.blok}>
      <Games
        title={props.blok.title}
        copy={props.blok.body}
        image={props.blok.image}
        items={props.blok.games}
        id="games"
        layout="natural-full"
      />
    </SbEditable>
  </Layout>
);

export default storyblokComponentGames;
