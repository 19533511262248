import React from 'react';

const storyblokComponentNotFound = (props) => (
  <article className="container p-5">
    <div className="row">
      <div className="col text-center">
        <h1>No preview</h1>
        <p>
          Sorry, there is no preview template available for the "
          {props.blok.component}" component
        </p>
      </div>
    </div>
  </article>
);

export default storyblokComponentNotFound;
